import mapboxgl from "mapbox-gl";

export default function createGeoLocate() {
  const map = window.map
  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true,
    showUserLocation: true,
    showAccuracyCircle: true
  });

  // Listen for the geolocate event to handle location updates
  geolocate.on('geolocate', (event) => {
    window.geolocateLocated()
  });

  // Listen for the error event to handle location errors
  geolocate.on('error', (error) => {
    console.error('Error getting location:', error);
    window.geolocateError()
    alert('Error getting location:', error)
  });
  geolocate.permissionAndTrigger = () => {
    if ('geolocation' in navigator) {
      // Geolocation is available
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          // Permission to access location has been granted
          geolocate.trigger();
        } else if (result.state === 'prompt') {
          // Permission to access location is not granted or denied yet
          navigator.geolocation.getCurrentPosition(() => {
            // Permission granted, trigger geolocation
            geolocate.trigger();
          }, () => {
            // Permission denied, display error message
            console.error('User denied permission to access location');
            alert('User denied permission to access location');
            window.geolocateError();
          });
        } else {
          // Permission to access location has been denied or is unavailable
          console.error('User denied permission to access location');
          alert('Please enable location services to use this feature');
          window.geolocateError();
        }
      });
    } else {
      // Geolocation is not available
      alert('Geolocation is not available on your device. Please contact support@hiiker.co');
      console.error('Geolocation is not available');
      window.geolocateError();
    }
  }

  map.addControl(geolocate);

  setTimeout(() => {
    const defaultButton = document.querySelector('.mapboxgl-ctrl-geolocate');
    defaultButton.style.display = 'none';
  }, 500);

  window.geoLocate = geolocate;
}
