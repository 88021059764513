<template>
  <div class="mt-3">
    <button @click="backClicked" class="btn btn-grey-light d-flex align-items-center p-0 pb-3"><span class="material-icons mr-2">arrow_left</span> Back</button>

    <input class="form-control py-3 btn-secondary" type="text" v-model="searchQuery" placeholder="Search..." @input="runSearch" />

    <p class="my-3"><strong>My Waypoints</strong></p>
    <div v-for="waypoint in waypointsForList" :key="waypoint.id">
      <div class="row">
        <div class="col-9">
           <p class="m-0 d-flex align-items-center">
            {{waypoint.title}}
          </p>
          <small class="text-muted d-flex align-items-center mt-1">{{new Date(waypoint.createdAt).toLocaleDateString()}} - {{new Date(waypoint.createdAt).toLocaleTimeString()}}
            <span class="mx-2">&middot;</span>
            <span role="button" class="text-muted d-flex align-items-center" @click="exportWaypointToGPX(waypoint)">GPX <span class="material-icons ml-1" style="font-size: 17px;">download</span></span>
            <span class="mx-2">&middot;</span>
            <span role="button" class="text-muted d-flex align-items-center" @click="deleteWaypoint(waypoint)">Delete <span class="material-icons ml-1" style="font-size: 17px;">delete</span></span>
          </small>
        </div>
        <div class="col-3">
          <button class="btn btn-block btn-xs btn-secondary" style="max-height: 34px;" @click="showWaypointOnMap(waypoint)">SHOW</button>
        </div>
      </div>
      <hr class="my-2">
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default defineComponent({
  data() {
    return {
      searchQuery: '',
    };
  },
  mounted() {
    this.runSearch();
  },
  computed: Object.assign(mapGetters({
    waypointsForList: 'waypoints/waypointsForList',
  })),
  methods: {
    async runSearch() {
      await this.$store.dispatch('waypoints/searchWaypoints', this.searchQuery);
    },
    backClicked() {
      this.$store.commit('waypoints/popPage');
    },
    showWaypointOnMap(waypoint) {
      this.$store.dispatch('waypoints/showWaypointOnMap', waypoint);
    },
    exportWaypointToGPX(waypoint) {
      this.$store.dispatch('waypoints/exportWaypointToGPX', waypoint);
    },
    async deleteWaypoint(waypoint) {
      await this.$store.dispatch('waypoints/deleteWaypoint', waypoint);
      await this.runSearch();
    },
  },
});
</script>
