/**
 * Opens Google Maps in a new tab showing directions to the provided latitude and longitude.
 *
 * @param {number} lat Latitude
 * @param {number} lon Longitude
 */
export function openGoogleMaps(lat, lon) {
  const googleMapsUrl = `https://www.google.com/maps/dir//${lat},${lon}`;
  window.open(googleMapsUrl, '_blank'); // Open the URL in a new tab
}
