<template>
  <div class="mt-3">
    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="clickedShowWaypoints">
      Show Waypoints on Map
      <span class="float-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="21" viewBox="0 0 42 21" fill="none">
          <rect width="42" height="21" rx="10.5" :fill="mapContainsWaypoints ? `#70C381` : `#363636`"/>
          <circle :cx="mapContainsWaypoints ? '30.5' : '11.5'" cy="10.5" r="8.5" fill="white"/>
        </svg>
      </span>
    </button>
    <hr class="my-2">
    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="addNewWaypoint">
      Add new Waypoint
      <span class="material-icons float-right">add_circle</span>
    </button>
    <hr class="my-2">
    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="clickViewAllWaypoints">
      View all Waypoints
      <span class="material-icons float-right">list</span>
    </button>
    <hr class="my-2">
    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="viewWaypointGroupsClicked">
      View Waypoint Groups
      <span class="material-icons float-right">folder</span>
    </button>
    <hr class="my-2">
    <input class="d-none" type="file" id="file-input" title="" @change="handleGPXImportFile" accept="application/gpx+xml">
    <label for="file-input" class="p-2 font-weight-normal text-white  btn-link d-flex justify-content-between" style="cursor: pointer">
      <span class="">Import Waypoints from GPX File</span>
      <span class="material-icons float-right">upload</span>
    </label>

  </div>
</template>

<script>
import { defineComponent } from 'vue';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { mapState } from 'vuex';
import { WaypointOptionsDialogPages } from '../../utils/models/WaypointOptionsDialogPages';

export default defineComponent({
  computed: Object.assign(mapState({
    mapContainsWaypoints: (state) => state.waypoints.mapContainsWaypoints,
  })),
  methods: {
    handleGPXImportFile(event) {
      const file = event.target.files[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'gpx') {
        // Handle the GPX file
        this.$store.dispatch('waypoints/importGPXFile', file);
      } else {
        alert('Please select a GPX file. It must end in .gpx');
      }
    },
    addNewWaypoint() {
      this.$store.commit('waypoints/toggleWaypointAddingNotification');
      $('#add-to-map-dialog').modal('hide');
    },
    clickViewAllWaypoints() {
      this.$store.commit('waypoints/setTopPage', WaypointOptionsDialogPages.Waypoints);
    },
    clickedShowWaypoints() {
      if (this.mapContainsWaypoints) {
        this.$store.dispatch('waypoints/removeWaypointsFromMap');
      } else {
        this.$store.dispatch('waypoints/addWaypointsToMap');
      }
      $('#add-to-map-dialog').modal('hide');
    },
    viewWaypointGroupsClicked() {
      this.$store.commit('waypoints/setTopPage', WaypointOptionsDialogPages.WaypointGroups);
    },
  },
});
</script>

<style scoped>
</style>
