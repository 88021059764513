<template>
  <div class="mt-3">

    <div class="d-flex justify-content-between align-items-center">
      <button @click="backClicked" class="btn btn-grey-light d-flex align-items-center p-0 pb-3"><span class="material-icons mr-2">arrow_left</span> Back</button>
      <button @click="printGroup" class="btn btn-secondary">PRINT</button>
    </div>

    <p class="mb-3"><strong>{{selectedWaypointGroup?.title}}</strong></p>

    <div v-for="waypoint in uniqueWaypoints" :key="waypoint.clientId">
      <div class="d-flex justify-content-between">
        <span>
          <p class="m-0">{{waypoint.title}}</p>
          <p><small class="text-muted">{{waypoint.createdAt}}</small></p>
        </span>
        <span class="float-right d-none d-flex justify-content-center">
          <button class="btn btn-xs btn-secondary" style="max-height: 34px;" @click="showClicked(waypoint)">SHOW</button>
        </span>
      </div>
      <hr class="my-2">
    </div>
    <span class="float-right d-none d-flex pb-3 justify-content-center">
      <button class="btn btn-xs btn-danger mr-2"  @click="deleteWaypointGroup">Delete Group</button>
      <button class="btn btn-xs btn-secondary" @click="exportGroupToGPX">Export all waypoints to GPX</button>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      selectedWaypointGroup: (state) => state.waypoints.selectedWaypointGroup,
    }),
    uniqueWaypoints() {
      const seenIds = new Set();
      if (!this.selectedWaypointGroup) {
        return [];
      }
      return this.selectedWaypointGroup.waypoints.filter((waypoint) => {
        if (seenIds.has(waypoint.id)) {
          return false;
        }
        seenIds.add(waypoint.id);
        return true;
      });
    },
  },
  methods: {
    backClicked() {
      this.$store.commit('waypoints/popPage');
    },
    showClicked(waypoint) {
      this.$store.dispatch('waypoints/showWaypointOnMap', waypoint);
    },
    printGroup() {
      window.location.href = `/print-maps?data_ids[]=${this.selectedWaypointGroup.id}&data_types[]=WaypointGroup`;
    },
    exportGroupToGPX() {
      this.$store.dispatch('waypoints/exportSelectedWaypointGroupToGPX');
    },
    deleteWaypointGroup() {
      this.$store.dispatch('waypoints/destroySelectedWaypointGroup');
    },
  },
});
</script>

<style scoped>
</style>
