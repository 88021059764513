<template>
  <div class="mt-3">

    <button @click="backClicked" class="btn btn-grey-light d-flex align-items-center p-0 pb-3"><span class="material-icons mr-2">arrow_left</span> Back</button>

    <div v-for="waypointGroup in allUserWaypointGroups" :key="waypointGroup.obfuscatedId">
      <div class="d-flex justify-content-between" role="button" @click="waypointGroupClicked(waypointGroup)">
        <span>
          <p>{{waypointGroup.title}} ({{waypointGroup.waypoints.length}})</p>
        </span>
        <span class="float-right d-none d-flex justify-content-center">
          <span class="material-icons mr-2"  @click="waypointGroupClicked(waypointGroup)">chevron_right</span>
        </span>
      </div>
      <hr class="my-2">
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { WaypointOptionsDialogPages } from '../../utils/models/WaypointOptionsDialogPages';

export default defineComponent({
  data() {
    return {
    };
  },
  mounted() {
    this.$store.dispatch('waypoints/getAllUserWaypointGroups');
  },
  methods: {
    backClicked() {
      this.$store.commit('waypoints/popPage');
    },
    waypointGroupClicked(waypointGroup) {
      this.$store.dispatch('waypoints/getWaypointGroup', waypointGroup.clientId);
      this.$store.commit('waypoints/setTopPage', WaypointOptionsDialogPages.WaypointGroupsWaypoints);
    },
  },
  computed: Object.assign(mapState({
    allUserWaypointGroups: (state) => state.waypoints.allUserWaypointGroups,
  })),
});
</script>
