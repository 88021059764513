<template>
  <div class="mt-3">
    <button @click="backClicked" class="btn btn-grey-light d-flex align-items-center p-0 pb-3"><span class="material-icons mr-2">arrow_left</span> Back</button>

    <label for="plannedTrailName" class=""><small><strong>Waypoint Groups</strong></small></label>
    <div class="row p-0 m-0">
      <div class="input-group col-12 p-0 m-0 d-flex align-items-center">
        <input class="form-control py-2 border-0" placeholder="Create a new group" @keyup.enter="createWaypointGroup($event)" v-model="newWaypointGroupName">
        <span class="input-group-append">
          <button class="btn btn-block btn-link d-flex align-items-center" type="button" @click="createWaypointGroup($event)">
            <span class="material-icons text-muted">add</span>
          </button>
        </span>
      </div>
    </div>
    <div v-for="waypointGroup of this.allUserWaypointGroups" :key="waypointGroup.id">
      <div class="row p-0 m-0">
        <div class="input-group col-12 p-0 m-0 d-flex align-items-center">
          <span class="form-control py-2 border-0"><p class="m-0">{{waypointGroup.title}}</p></span>
          <span class="input-group-append">
          <button class="btn btn-block btn-link d-flex align-items-center" type="button" @click="updateWaypointGroup($event, waypointGroup)">
            <template v-if="waypointGroupIncludesWaypoint(waypointGroup)">
              <span class="material-icons text-primary">check</span>
            </template>
            <template v-else>
              <span class="material-icons text-muted">add</span>
            </template>
          </button>
        </span>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 p-0 m-0">
          <hr class="my-1">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newWaypointGroupName: '',
    };
  },
  mounted() {
    this.$store.dispatch('waypoints/getAllUserWaypointGroups');
  },
  computed: {
    allUserWaypointGroups() {
      return this.$store.state.waypoints.allUserWaypointGroups;
    },
    selectedWaypoint() {
      return this.$store.state.waypoints.selectedWaypoint;
    },
  },
  methods: {
    backClicked() {
      this.$store.commit('waypoints/popPage');
    },
    waypointGroupIncludesWaypoint(waypointGroup) {
      return waypointGroup.waypoints.find((waypoint) => waypoint.clientId === this.selectedWaypoint.clientId) != null;
    },
    updateWaypointGroup(event, waypointGroup) {
      event.preventDefault();
      if (this.waypointGroupIncludesWaypoint(waypointGroup)) {
        waypointGroup.waypoints = waypointGroup.waypoints.filter((waypoint) => waypoint.clientId !== this.selectedWaypoint.clientId);
      } else {
        waypointGroup.waypoints.push(this.selectedWaypoint);
      }
      this.$store.dispatch('waypoints/updateWaypointGroup', {
        id: waypointGroup.id,
        title: waypointGroup.title,
        waypointIds: waypointGroup.waypoints.map((waypoint) => waypoint.id),
      });
    },
    createWaypointGroup(event) {
      event.preventDefault();
      this.$store.dispatch(
        'waypoints/createWaypointGroup',
        {
          title: this.newWaypointGroupName,
          waypointIds: [this.selectedWaypoint.id],
        },
      );
      this.newWaypointGroupName = '';
    },
  },
};
</script>

<style scoped>

</style>
